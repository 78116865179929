import React, { useEffect, useState, useContext, Fragment } from "react";
import { Link } from "gatsby";
import CartContext from "../../context/CartContext";

import ProductImages from "./ProductImages";
import ExtraOptionsCheck from "./ExtraOptionsCheck";
import RadioButton from "../UI/RadioButton";
import SkeletonShopOpen from "../Skeletons/SkeletonShopOpen";

import * as classes from "./ProductOpen.module.css";

const ProductOpen = (props) => {
  const { updateLineItem, getProductById } = useContext(CartContext);
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState();

  useEffect(() => {
    getProductById(props.storefrontId).then((result) => {
      setProduct(result);
      setSelectedVariant(result.variants.find((variant) => variant.available));
    });
  }, [getProductById, props.storefrontId, props.variants.length]);

  const handleSizeChange = (e) => {
    const newVariant = product?.variants.find((v) => v.id === e.target.value);
    setSelectedVariant(newVariant);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const variantId = selectedVariant.id;
    updateLineItem({ variantId, quantity: 1 });
  };

  return (
    <>
      {!selectedVariant && <SkeletonShopOpen />}
      <div className={classes.wrapper}>
        <section className={classes.shopItemOpen}>
          {product?.availableForSale && !!selectedVariant && (
            <>
              <div className={classes.productGallery}>
                <ProductImages images={props.images} />
              </div>
              <div className={classes.content}>
                <span className={classes.preTitle}>Goriot</span>
                <h1 className={classes.productTitle}>{props.title}</h1>
                <h2 className={classes.productPrice}>
                  {selectedVariant.price < selectedVariant.compareAtPrice ? (
                    <>
                      <span className={classes.salePrice}>
                        ${selectedVariant.compareAtPrice} {props.currencyCode}
                      </span>
                      ${selectedVariant.price} {props.currencyCode}
                    </>
                  ) : (
                    `$${selectedVariant.price} ${props.currencyCode}`
                  )}
                </h2>
                <ExtraOptionsCheck />
                <div className={classes.interact}>
                  <div className={classes.radioContainer}>
                    {product?.variants.map((variant, i) => (
                      <Fragment key={i}>
                        {variant.available ? (
                          <RadioButton
                            key={variant.id}
                            value={variant.id}
                            label={variant.title}
                            onChange={handleSizeChange}
                            checked={variant === selectedVariant}
                            name="variants"
                            disabled={false}
                          />
                        ) : (
                          <RadioButton
                            key={variant.id}
                            value={variant.id}
                            label={variant.title}
                            onChange={handleSizeChange}
                            checked={variant === selectedVariant}
                            name="variants"
                            disabled={true}
                          />
                        )}
                      </Fragment>
                    ))}
                  </div>

                  <div className={classes.shopBtns}>
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className={classes.atc}
                      disabled={!selectedVariant.available}
                    >
                      {!selectedVariant.available
                        ? "Out of stock"
                        : "Add To Cart"}
                    </button>
                    <Link to="/shop" className={classes.viewAllBtn}>
                      View All
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
    </>
  );
};

export default ProductOpen;
