import React from "react";
import Shimmer from "./Shimmer";

import SkeletonElement from "./SkeletonElement";

const SkeletonShopOpen = ({ theme }) => {
  const themeClass = theme || "light";

  return (
    <div className={`skeleton-wrapper ${themeClass}`}>
      <div className="skeleton-shopOpen">
        <div className="skeleton-left">
          <SkeletonElement type="bigImage" />
          <div className="thumbs">
            <SkeletonElement type="thumbnail" />
            <SkeletonElement type="thumbnail" />
            <SkeletonElement type="thumbnail" />
          </div>
        </div>
        <div className="skeleton-right">
          <SkeletonElement type="subTitle" />
          <SkeletonElement type="title" />
          <SkeletonElement type="price" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <SkeletonElement type="text" />
          <div className="buttons">
            <SkeletonElement type="button" />
            <SkeletonElement type="button" />
          </div>
        </div>
      </div>
      <Shimmer />
    </div>
  );
};

export default SkeletonShopOpen;
