import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo/seo";
import ProductOpen from "../components/Products/ProductOpen";

export const query = graphql`
  query ($storefrontId: String!) {
    product: shopifyProduct(storefrontId: { eq: $storefrontId }) {
      id
      title
      storefrontId
      description
      variants {
        id
        title
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        localFile {
          childImageSharp {
            gatsbyImageData(
              blurredOptions: { width: 3 }
              placeholder: NONE
              quality: 100
            )
            id
          }
        }
      }
    }
  }
`;

const ProductPage = (props) => {
  const { data } = props;

  return (
    <>
      <Seo title={data.product.title} description={data.product.description} />
      <ProductOpen
        title={data.product.title}
        price={data.product.priceRangeV2.maxVariantPrice.amount}
        currencyCode={data.product.priceRangeV2.maxVariantPrice.currencyCode}
        description={data.product.description}
        images={data.product.images}
        variants={data.product.variants}
        storefrontId={data.product.storefrontId}
      />
    </>
  );
};

export default ProductPage;
