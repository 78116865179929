// extracted by mini-css-extract-plugin
export var shopItemOpen = "ProductOpen-module--shopItemOpen--IYRw9";
export var content = "ProductOpen-module--content--hdYeN";
export var preTitle = "ProductOpen-module--preTitle--1Q35b";
export var productTitle = "ProductOpen-module--productTitle--VFCzX";
export var productPrice = "ProductOpen-module--productPrice--2WsnQ";
export var salePrice = "ProductOpen-module--salePrice--DR4LF";
export var interact = "ProductOpen-module--interact--39U4e";
export var radioContainer = "ProductOpen-module--radioContainer--1Evx4";
export var shopBtns = "ProductOpen-module--shopBtns--34lHy";
export var atc = "ProductOpen-module--atc--4eUir";
export var viewAllBtn = "ProductOpen-module--viewAllBtn--1BzQZ";
export var wrapper = "ProductOpen-module--wrapper--2_luT";
export var productGallery = "ProductOpen-module--productGallery--204Nk";