import React, { Fragment, useState } from "react";

import RadioButton from "../UI/RadioButton";

import * as classes from "./ExtraOptionsCheck.module.css";

const ExtraOptionsCheck = (props) => {
  const [extraOptions, setExtraOptions] = useState("Description");

  const options = ["Description", "Sizing", "Care"];

  const classOne =
    extraOptions === options[0]
      ? `${classes.moreInfo} ${classes.show}`
      : `${classes.moreInfo}`;

  const classTwo =
    extraOptions === options[1]
      ? `${classes.moreInfo} ${classes.show}`
      : `${classes.moreInfo}`;

  const classThree =
    extraOptions === options[2]
      ? `${classes.moreInfo} ${classes.show}`
      : `${classes.moreInfo}`;

  const handleOptionChange = (e) => {
    setExtraOptions(e.target.value);
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
          {options.map((o, i) => (
            <RadioButton
              value={o}
              label={o}
              key={i}
              onChange={handleOptionChange}
              checked={extraOptions === o}
              name="extraOptions"
            />
          ))}
        </div>
        <div className={classes.optionsContainer}>
          <div className={classOne}>
            <p>
              100% Deadstock American cotton. Lightweight and soft. Spun to
              create slubby texture (similar to t-shirts from the mid-90s).
              Silkscreen graphic printed. Boxy fit – longer sleeves and
              shortened body.
              <br />
              <br />
              Please be aware that the t-shirt will stretch 2-3cm in the
              shoulders & chest with wear. The collar is initially snug, however
              will stretch to a perfect fit with wear.
            </p>
          </div>
          <div className={classTwo}>
            <div>
              <p>
                Please allow for some variance in sizing, due to the stretchy
                nature of the knitted fabric and handmade construction.
                <br />
              </p>
              <table className={classes.sizeTable}>
                <thead>
                  <tr>
                    <th aria-label="Size"></th>
                    <th>Small</th>
                    <th>Medium</th>
                    <th>Large</th>
                    <th>XL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Length (cm)</td>
                    <td>61cm</td>
                    <td>63cm</td>
                    <td>65cm</td>
                    <td>67cm</td>
                  </tr>
                  <tr>
                    <td>Chest (cm)</td>
                    <td>49cm</td>
                    <td>51cm</td>
                    <td>53cm</td>
                    <td>55cm</td>
                  </tr>
                  <tr>
                    <td>Shoulder (cm)</td>
                    <td>43cm</td>
                    <td>45cm</td>
                    <td>47cm</td>
                    <td>49cm</td>
                  </tr>
                  <tr>
                    <td>Sleeve (cm)</td>
                    <td>26cm</td>
                    <td>26cm</td>
                    <td>27cm</td>
                    <td>28cm</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className={classThree}>
            <p>
              Cotton garments stretch with wear and shrink with warm-hot washes.
              We recommend washing at 40ºC or less, on medium setting and to
              hang dry.
              <br />
              <br />
              Our 'Natural Slub' t-shirts get better with age and wear, with
              time you can expect to see improved drape and softness.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ExtraOptionsCheck;
